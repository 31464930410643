import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./user/user.reducer";
import postsReducer from "./posts/posts.reducer";
import usersReducer from "./users/users.reducer";
import reviewsReducer from "./reviews/reviews.reducer";

export const rootReducer = combineReducers({
  user: userReducer,
  postsData: postsReducer,
  usersData: usersReducer,
  reviewsData: reviewsReducer,
});

const configStorage = {
  key: "root",
  storage,
  whitelist: ["cartData"],
};

export default persistReducer(configStorage, rootReducer);
