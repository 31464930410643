import { all, call } from "redux-saga/effects";
import userSagas from "./user/user.sagas";
import postsSagas from "./posts/posts.sagas";
import usersSagas from "./users/users.sagas";
import reviewsSagas from "./reviews/reviews.sagas";

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(postsSagas),
    call(usersSagas),
    call(reviewsSagas),
  ]);
}
