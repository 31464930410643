const reviewsTypes = {
  ADD_NEW_REVIEW_START: "ADD_NEW_REVIEW_START",
  FETCH_REVIEWS_START: "FETCH_REVIEWS_START",
  SET_REVIEWS: "SET_REVIEWS",
  DELETE_REVIEW_START: "DELETE_REVIEW_START",
  FETCH_REVIEW_START: "FETCH_REVIEW_START",
  SET_REVIEW: "SET_REVIEW",
};

export default reviewsTypes;
