import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

//REDUX
import { useDispatch } from "react-redux";
import { checkUserSession } from "./redux/user/user.actions";
import { WithAuth } from "./hooks/hoc";

//ASSETS
import "./Styles.scss";
import { Navigation } from "./components/Navigation";
import { LoaderFull } from "./components/Loader";
import ScrollToTop from "./components/ScrollToTop";

const Homepage = lazy(() =>
  import("./pages/Homepage").then((module) => ({ default: module.Homepage }))
);
const SignIn = lazy(() =>
  import("./pages/Auth").then((module) => ({ default: module.SignIn }))
);
const Register = lazy(() =>
  import("./pages/Auth").then((module) => ({ default: module.Register }))
);
const Account = lazy(() =>
  import("./pages/Account").then((module) => ({
    default: module.Account,
  }))
);
const Servicii = lazy(() =>
  import("./pages/Servicii").then((module) => ({ default: module.Servicii }))
);
const Paramedical = lazy(() =>
  import("./pages/Servicii").then((module) => ({ default: module.Paramedical }))
);
const Areola = lazy(() =>
  import("./pages/Servicii").then((module) => ({ default: module.Areola }))
);
const Cicatrici = lazy(() =>
  import("./pages/Servicii").then((module) => ({ default: module.Cicatrici }))
);
const BuzeParamedical = lazy(() =>
  import("./pages/Servicii").then((module) => ({
    default: module.BuzeParamedical,
  }))
);
const Cosmetic = lazy(() =>
  import("./pages/Servicii").then((module) => ({ default: module.Cosmetic }))
);
const Sprancene = lazy(() =>
  import("./pages/Servicii").then((module) => ({
    default: module.Sprancene,
  }))
);
const Eyeliner = lazy(() =>
  import("./pages/Servicii").then((module) => ({
    default: module.Eyeliner,
  }))
);
const BuzeCosmetic = lazy(() =>
  import("./pages/Servicii").then((module) => ({
    default: module.BuzeCosmetic,
  }))
);
const Booking = lazy(() =>
  import("./pages/Booking").then((module) => ({ default: module.Booking }))
);
const About = lazy(() =>
  import("./pages/About").then((module) => ({ default: module.About }))
);
const Info = lazy(() =>
  import("./pages/Info").then((module) => ({ default: module.Info }))
);
const Contact = lazy(() =>
  import("./pages/Contact").then((module) => ({
    default: module.Contact,
  }))
);
const Page404 = lazy(() =>
  import("./pages/Page404").then((module) => ({ default: module.Page404 }))
);

function Routing() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(checkUserSession());
  }, [dispatch]);

  return (
    <>
      <Navigation
        homepage={
          location.pathname.slice(1) === ""
            ? true
            : false && location.pathname.slice(1) === "programare"
            ? true
            : false
        }
        title={
          location.pathname.slice(1) === "create-new-account"
            ? " "
            : location.pathname.slice(1).replace(/-/g, " ")
        }
        backButton={
          location.pathname === "/"
            ? false
            : true && location.pathname === "/programare"
            ? false
            : true
        }
        extraClassName={
          location.pathname === "/" || "/programare" ? "homepage" : ""
        }
      />
      <Suspense fallback={<LoaderFull />}>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/logare" element={<SignIn />} />
          <Route exact path="/creare-cont-nou" element={<Register />} />
          <Route
            exact
            path="/cont"
            element={
              <WithAuth>
                <Account />
              </WithAuth>
            }
          />
          <Route exact path="/servicii" element={<Servicii />} />
          <Route exact path="/servicii/paramedical" element={<Paramedical />} />
          <Route
            exact
            path="/servicii/paramedical/areola"
            element={<Areola />}
          />
          <Route
            exact
            path="/servicii/paramedical/cicatrici"
            element={<Cicatrici />}
          />
          <Route
            exact
            path="/servicii/paramedical/buze"
            element={<BuzeParamedical />}
          />
          <Route exact path="/servicii/cosmetic" element={<Cosmetic />} />
          <Route
            exact
            path="/servicii/cosmetic/sprancene"
            element={<Sprancene />}
          />
          <Route
            exact
            path="/servicii/cosmetic/eyeliner"
            element={<Eyeliner />}
          />
          <Route
            exact
            path="/servicii/cosmetic/buze"
            element={<BuzeCosmetic />}
          />
          <Route exact path="/programare" element={<Booking />} />
          <Route exact path="/despre" element={<About />} />
          <Route exact path="/informatii" element={<Info />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default Routing;
