import userTypes from "./user.types";

const INITIAL_STATE = {
  currentUser: null,
  resetPasswordSuccess: false,
  userErr: [],
  loading: false,
  emailVerificationSent: false,
  isVerified: false,
  isOnline: false,
  lastSeen: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...action.payload,
          isOnline: true,
          lastSeen: new Date(),
        },
        userErr: [],
      };

    case userTypes.SIGN_IN_ERROR:
      return {
        ...state,
        currentUser: null,
        userErr: [action.payload],
      };

    case userTypes.RESET_USER_STATE:
    case userTypes.SIGN_OUT_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...action.payload,
          isOnline: true,
          lastSeen: new Date(),
        },
        ...INITIAL_STATE,
      };
  
    case userTypes.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };

    case userTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case userTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: action.payload,
      };

    case userTypes.EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        emailVerificationSent: true,
        isVerified: action.payload,
      };

    case userTypes.EMAIL_VERIFICATION_ERROR:
      return {
        ...state,
        userErr: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
