import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createSelector } from "reselect";

const currentUserSelector = (state) => state.user.currentUser;

const mapState = createSelector([currentUserSelector], (currentUser) => ({
  currentUser,
}));

export const checkUserIsAdmin = (currentUser) => {
  if (!currentUser || !Array.isArray(currentUser.userRoles)) return false;
  const { userRoles } = currentUser;
  if (userRoles.includes("admin")) return true;

  return false;
};

function useAuth(props) {
  const { currentUser } = useSelector(mapState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate("/logare");
    }
  }, [currentUser, navigate]);

  return currentUser;
}

function useAdminAuth(props) {
  const { currentUser } = useSelector(mapState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkUserIsAdmin(currentUser)) return null;
  }, [currentUser, navigate]);

  return currentUser;
}

export const WithAuth = (props) => useAuth(props) && props.children;
export const WithAdminAuth = (props) => useAdminAuth(props) && props.children;
