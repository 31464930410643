import { takeLatest, put, all, call } from "redux-saga/effects";
import { setReviews, setReview, fetchReviewsStart } from "./reviews.actions";
import {
  handleAddReview,
  handleFetchReviews,
  handleFetchReview,
  handleDeleteReview,
} from "./reviews.helpers";
import reviewsTypes from "./reviews.types";

export function* addReview({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddReview({
      ...payload,
      createdDate: timestamp,
    });
    yield put(fetchReviewsStart());
  } catch (err) {}
}

export function* onAddReviewStart() {
  yield takeLatest(reviewsTypes.ADD_NEW_REVIEW_START, addReview);
}

export function* fetchReviews({ payload }) {
  try {
    const reviews = yield handleFetchReviews(payload);
    yield put(setReviews(reviews));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchReviewsStart() {
  yield takeLatest(reviewsTypes.FETCH_REVIEWS_START, fetchReviews);
}

export function* fetchReview({ payload }) {
  try {
    const review = yield handleFetchReview(payload);
    yield put(setReview(review));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchReviewStart() {
  yield takeLatest(reviewsTypes.FETCH_REVIEW_START, fetchReview);
}

export function* deleteReview({ payload }) {
  try {
    yield handleDeleteReview(payload);
    yield put(fetchReviewsStart());
  } catch (err) {
    // console.log(err);
  }
}

export function* onDeleteReviewStart() {
  yield takeLatest(reviewsTypes.DELETE_REVIEW_START, deleteReview);
}

export default function* reviewsSagas() {
  yield all([
    call(onAddReviewStart),
    call(onFetchReviewsStart),
    call(onDeleteReviewStart),
    call(onFetchReviewStart),
  ]);
}
