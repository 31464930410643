import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();

// firestore.enablePersistence();

export { firestore, auth, storage };

export const GoogleProvider = new firebase.auth.GoogleAuthProvider();
GoogleProvider.setCustomParameters({ prompt: "select_account" });

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};

function generateUsername(displayName) {
  const firstName = displayName.split(" ")[0].toLowerCase();
  const randomChars = Math.random().toString(36).substring(2, 7);
  return `${firstName}${randomChars}`;
}

export const handleUserProfile = async ({ userAuth, additionalData }) => {
  if (!userAuth) return;
  const { uid } = userAuth;

  const userRef = firestore.doc(`users/${uid}`);
  const snapshot = await userRef.get();

  if (!snapshot.exists) {
    const { displayName, email, photoURL, username } = userAuth;
    const timestamp = new Date();
    const userRoles = ["user"];
    const phoneNumber = null;
    const website = null;
    const gender = null;
    const birthDate = null;
    const isOnline = false;
    const isVerified = false;
    const privacy = false;

    let newUsername;

    if (userAuth.providerData[0].providerId === "google.com") {
      newUsername = generateUsername(displayName);
    } else {
      newUsername = username;
    }

    try {
      await userRef.set({
        displayName,
        username: newUsername,
        email,
        photoURL,
        phoneNumber,
        website,
        privacy,
        gender,
        birthDate,
        createdDate: timestamp,
        userRoles,
        isOnline,
        isVerified,
        ...additionalData,
      });
    } catch (err) {
      console.log(err);
    }
  }

  return userRef;
};
