import { auth, firestore } from "./../../database/utils";

export const handleResetPasswordAPI = (email) => {
  const config = {
    url: "https://clarytas.web.app",
  };

  return new Promise((resolve, reject) => {
    auth
      .sendPasswordResetEmail(email, config)
      .then(() => {
        resolve();
      })
      .catch(() => {
        const err = ["Email not found. Please try again."];
        reject(err);
      });
  });
};

export const handleEmailVerificationAPI = (email) => {
  const actionCodeSettings = {
    url: "https://clarytas.web.app/email-verified",
  };

  return new Promise((resolve, reject) => {
    auth
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserByEmail = async (email) => {
  const userRef = firestore
    .collection("users")
    .where("email", "==", email)
    .limit(1);
  const userSnapshot = await userRef.get();

  if (!userSnapshot.empty) {
    const userData = userSnapshot.docs[0].data();
    return { exists: true, ...userData };
  } else {
    return { exists: false };
  }
};

//UPDATE USER INFO
export const handleUpdateUserProfile = (userData) => {
  const { userID, isOnline, lastSeen, ...updateData } = userData;

  return new Promise((resolve, reject) => {
    firestore
      .collection("users")
      .doc(userID)
      .update({ ...updateData, isOnline, lastSeen }) 
      .then(() => resolve())
      .catch((err) => reject(err));
  });
};

