import React from "react";
import { useNavigate } from "react-router-dom";

//ASSETS
import { HiArrowLeft } from "react-icons/hi";

export function ButtonPrimary({ title, ...otherProps }) {
  return (
    <button className="btnPrimary" {...otherProps}>
      <span>{title}</span>
      <span className="loaderBtn" />
    </button>
  );
}
export function ButtonSecondary({ title, ...otherProps }) {
  return (
    <button className="btnSecondary" {...otherProps}>
      {title}
    </button>
  );
}
export function ButtonTertiary({ title, ...otherProps }) {
  return (
    <button className="btnTertiary" {...otherProps}>
      {title}
    </button>
  );
}
export function ButtonSettings({ title, ...otherProps }) {
  return (
    <button className="btnSettings" {...otherProps}>
      {title}
    </button>
  );
}
export function ButtonBack({ title, ...otherProps }) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/cont");
    }
  };

  return (
    <button
      type="button"
      aria-label="backButton"
      className="backButton"
      {...otherProps}
      onClick={handleGoBack}
    >
      <HiArrowLeft />
    </button>
  );
}
