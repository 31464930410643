import React from "react";
import { ButtonBack } from "./Buttons";
import {
  HiOutlineCalendar,
  HiOutlineLightBulb,
  HiOutlinePhone,
  HiOutlineUserCircle,
} from "react-icons/hi";
import { BiHomeAlt } from "react-icons/bi";
import { NavLink, useLocation } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

const currentUserSelector = (state) => state.user.currentUser;

const mapState = createSelector([currentUserSelector], (currentUser) => ({
  currentUser,
}));

export function Navigation(props) {
  const { currentUser } = useSelector(mapState);
  const location = useLocation();

  return (
    <>
      <nav className={`top ${props.extraClassName}`}>
        {props.backButton && <ButtonBack />}
        {(location.pathname === "/" || location.pathname === "/programare") && (
          <img src="./assets/logo/skinartLogoLung.png" alt="SkinArt Studio" />
        )}
        {location.pathname !== "/" && location.pathname !== "/programare" && (
          <span>{props.title}</span>
        )}
      </nav>
      <nav className="bottom">
        <NavLink to="/">
          <button
            aria-label="Navigation home button"
            name="Navigation home button"
          >
            <BiHomeAlt />
            <small>Acasa</small>
          </button>
        </NavLink>
        <NavLink to="/informatii">
          <button
            aria-label="Navigation info button"
            name="Navigation info button"
          >
            <HiOutlineLightBulb />
            <small>Informatii</small>
          </button>
        </NavLink>
        <NavLink to="/programare">
          <button
            aria-label="Navigation booking button"
            name="Navigation booking button"
          >
            <HiOutlineCalendar />
            <small>Programare</small>
          </button>
        </NavLink>
        <NavLink to="/contact">
          <button aria-label="Navigation contact button">
            <HiOutlinePhone />
            <small>Contact</small>
          </button>
        </NavLink>
        {currentUser ? (
          <NavLink to="/cont">
            <button
              aria-label="Navigation account button"
              name="Navigation account button"
            >
              <HiOutlineUserCircle />
              <small>Cont</small>
            </button>
          </NavLink>
        ) : (
          <NavLink
            to="/logare"
            className={
              location.pathname === "/creare-cont-nou" ? "active" : null
            }
          >
            <button
              aria-label="Navigation account button"
              name="Navigation account button"
            >
              <HiOutlineUserCircle />
              <small>Cont</small>
            </button>
          </NavLink>
        )}
      </nav>
    </>
  );
}
