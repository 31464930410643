const postsTypes = {
  ADD_NEW_POST_START: "ADD_NEW_POST_START",
  FETCH_POSTS_START: "FETCH_POSTS_START",
  SET_POSTS: "SET_POSTS",
  DELETE_POST_START: "DELETE_POST_START",
  FETCH_POST_START: "FETCH_POST_START",
  SET_POST: "SET_POST",
};

export default postsTypes;
