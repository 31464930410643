import reviewsTypes from "./reviews.types";
import reviewTypes from "./reviews.types";

const INITIAL_STATE = {
  reviews: [],
  review: {},
};

const reviewsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case reviewTypes.SET_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
      };
    case reviewsTypes.SET_REVIEW:
      return {
        ...state,
        review: action.payload,
      };
    default:
      return state;
  }
};

export default reviewsReducer;
