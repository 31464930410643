import { takeLatest, call, all, put, select, take } from "redux-saga/effects";
import {
  auth,
  handleUserProfile,
  getCurrentUser,
  GoogleProvider,
  firestore,
} from "./../../database/utils";
import userTypes from "./user.types";
import {
  signInSuccess,
  signOutUserSuccess,
  resetPasswordSuccess,
  userError,
  emailVerificationSuccess,
  emailVerificationError,
  signInError,
  emailVerificationStart,
} from "./user.actions";
import { handleResetPasswordAPI, handleUpdateUserProfile } from "./user.helpers";

export function* getSnapshotFromUserAuth(user, additionalData = {}) {
  try {
    const userRef = yield call(handleUserProfile, {
      userAuth: user,
      additionalData,
    });
    const snapshot = yield userRef.get();
    const userData = {
      id: snapshot.id,
      isOnline: additionalData.isSignIn ? true : false,
      lastSeen: new Date(),
      ...snapshot.data(),
    };
    yield put(signInSuccess(userData));
  } catch (err) {
    // console.error(err)
  }
}
export function* isUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser();
    if (!userAuth) return;
    yield getSnapshotFromUserAuth(userAuth);
  } catch (err) {
    // console.log(err);
  }
}

export function* onCheckUserSession() {
  yield takeLatest(userTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

// SEND EMAIL VERIFICATION
export function* sendEmailVerification() {
  try {
    const currentUser = yield select((state) => state.user.currentUser);
    const userRef = firestore.doc(`users/${currentUser.id}`);

    yield call([auth.currentUser, auth.currentUser.sendEmailVerification]);
    console.log("Email verification sent");

    yield take(userTypes.EMAIL_VERIFICATION_SUCCESS);
    console.log("Email verified by user");

    const user = yield call(getCurrentUser);
    console.log("Current user:", user);

    yield call([userRef, userRef.update], { isVerified: true });
    console.log("isVerified field updated in database");

    yield put(emailVerificationSuccess(true));
  } catch (error) {
    yield put(emailVerificationError(error.message));
  }
}
export function* onEmailVerificationStart() {
  yield takeLatest(userTypes.EMAIL_VERIFICATION_START, sendEmailVerification);
}

// UPDATE EMAIL VERIFICATION STATUS
export function* updateUserVerificationStatus() {
  yield put(emailVerificationSuccess(true));
}
export function* onEmailVerificationSuccess() {
  yield takeLatest(
    userTypes.EMAIL_VERIFICATION_SUCCESS,
    updateUserVerificationStatus
  );
}

// AUTH LOGIN
export function* emailSignIn({ payload: { email, password } }) {
  try {
    const { user } = yield auth.signInWithEmailAndPassword(email, password);
    yield getSnapshotFromUserAuth(user, { isSignIn: true });
  } catch (err) {
    if (err.code === "auth/wrong-password") {
      yield put(signInError(["Wrong password"]));
    } else if (err.code === "auth/user-not-found") {
      yield put(signInError(["Email not found"]));
    } else if (err.code === "auth/too-many-requests") {
      yield put(
        signInError(["Too many requests, please try again in a few minutes"])
      );
    } else {
      yield put(signInError([err.message]));
    }
  }
}


export function* signInWithEmailAndPassword({ payload: { email, password } }) {
  try {
    const { user } = yield auth.signInWithEmailAndPassword(email, password);
    yield getSnapshotFromUserAuth(user);
  } catch (err) {
    yield put(signInError(err.message));
  }
}
export function* onEmailSignInStart() {
  yield takeLatest(userTypes.SIGN_IN_START, emailSignIn);
}

// AUTH REGISTER
export function* signUpUser({
  payload: {
    username,
    displayName,
    email,
    password,
    confirmPassword,
    photoURL,
  },
}) {
  if (password !== confirmPassword) {
    const err = ["Parolele nu seamna"];
    yield put(userError(err));
    return;
  }
  try {
    const { user } = yield auth.createUserWithEmailAndPassword(email, password);
    const additionalData = {
      username,
      displayName,
      email,
      photoURL,
    };
    yield getSnapshotFromUserAuth(user, additionalData);
    yield put(emailVerificationStart({ email }));
  } catch (err) {
    console.log(err);
  }
}
export function* onSignUpUserStart() {
  yield takeLatest(userTypes.SIGN_UP_USER_START, signUpUser);
}

// RESET PASSWORD
export function* resetPassword({ payload: { email } }) {
  try {
    yield call(handleResetPasswordAPI, email);
    yield put(resetPasswordSuccess());
  } catch (err) {
    yield put(userError(err));
  }
}
export function* onResetPasswordStart() {
  yield takeLatest(userTypes.RESET_PASSWORD_START, resetPassword);
}

//GOOGLE AUTH
export function* googleSignIn() {
  try {
    const { user } = yield auth.signInWithPopup(GoogleProvider);
    yield getSnapshotFromUserAuth(user, { isSignIn: true });
  } catch (err) {
    // console.log(err);
  }
}
export function* onGoogleSignInStart() {
  yield takeLatest(userTypes.GOOGLE_SIGN_IN_START, googleSignIn);
}


//SIGN OUT
export function* signOutUser() {
  try {
    const currentUser = yield select((state) => state.user.currentUser);

    if (currentUser && currentUser.id) {
      yield call([auth, auth.signOut]);
      yield put(signOutUserSuccess());
    } else {
      console.error(
        "Cannot update online status: currentUser or id is missing"
      );
    }
  } catch (err) {
    console.error("Sign out error:", err);
  }
}

export function* onSignOutUserStart() {
  yield takeLatest(userTypes.SIGN_OUT_USER_START, signOutUser);
}

//UPDATE USER
export function* updateUserProfile({ payload }) {
  try {
    yield call(handleUpdateUserProfile, payload);
    yield put({ type: userTypes.UPDATE_USER_PROFILE_SUCCESS });
    // Success
  } catch (error) {
    yield put({ type: userTypes.UPDATE_USER_PROFILE_FAILURE, error });
    // Error
  }
}
export function* onUpdateUserProfileStart() {
  yield takeLatest(userTypes.UPDATE_USER_PROFILE_START, updateUserProfile);
}

export default function* userSagas() {
  yield all([
    call(onEmailSignInStart),
    call(onSignUpUserStart),
    call(onCheckUserSession),
    call(onResetPasswordStart),
    call(onGoogleSignInStart),
    call(onSignOutUserStart),
    call(onEmailVerificationStart),
    call(onEmailVerificationSuccess),
    call(onUpdateUserProfileStart),
  ]);
}
